<template>
  <div>
    <AppCarousel
      :cards="featuredVideos"
      :cardBorder="true"
      type="videos"
      v-if="display === 'featured-videos'"
    />

    <AppCarousel
      :cards="newVideos"
      :cardBorder="true"
      type="videos"
      v-if="display === 'new-videos'"
    />

    <AppCarousel
      :cards="cards"
      :cardBorder="true"
      type="videos"
      v-if="display === 'other-videos'"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  export default {
    props : {
      display : {
        type     : String,
        required : true,
      },
      cards : {
        type     : Array,
        required : false,
      },
    },
    computed : {
      ...mapGetters({
        subscription   : 'subscription/subscription',
        user           : 'user/user',
        featuredVideos : 'media/featuredVideos',
        newVideos      : 'media/newVideos',
      }),
    },
    components : {
      'AppCarousel' : () => import('@/components/carousel/AppCarousel'),
    },
    mounted() {
      this.$store.dispatch('media/getFeaturedVideos');
      this.$store.dispatch('media/getNewVideos');
    },
  }
</script>